<template>
	<el-dialog :visible.sync="visible" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
		class="role-dialog" width="550px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm">
			<el-form-item label="课程名称" prop="courseId">
				<el-select size="small"
					v-model="dataForm.courseId"
					filterable
					:disabled="readonly"
					:multiple="multiple"
					@change="handleCourse">
					<el-option v-for="(item,i) in courseList" :key="i" :value="item.id" :label="item.name">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="课程编号" prop="courseId">
				<el-input size="small" v-model="courseCode" placeholder="课程编号" :disabled="true"></el-input>
			</el-form-item>
			<div class="flex">
				<el-form-item class="flex-1" label="开始时间" prop="startTime">
					<el-date-picker v-model="dataForm.startTime" type="date" value-format="yyyy-MM-dd"
						:disabled="publishFlag == 1" @change="handleTime" placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item class="flex-1" label="结束时间" prop="endTime">
					<el-input size="small" v-model="dataForm.endTime" placeholder="结束时间" :disabled="true"></el-input>
				</el-form-item>
			</div>
			<el-form-item label="接收对象">
				<el-checkbox v-model="sendType" :true-label="1" :false-label="1" class="send-type-checkbox"
					:disabled="readonly"> 以通讯组为单位选择 </el-checkbox>
				<el-checkbox v-model="sendType" :true-label="5" :false-label="5" class="send-type-checkbox"
					:disabled="readonly"> 以经销商分组为单位选择 </el-checkbox>
			</el-form-item>
			<el-form-item label="">
				<el-cascader v-if="sendType == 1" :disabled="readonly" :show-all-levels="true"
					:options="departmentOptions" :props="machineProps" class="full-width" placeholder="请选择通讯组"
					ref="myCascader" v-model="cascaderArr" clearable filterable @change="deviceCascaderVisableChange">
				</el-cascader>
				<el-select v-else-if="sendType == 5" v-model="dealerArr" :disabled="readonly" multiple filterable
					clearable placeholder="请选择经销商分组" class="full-width">
					<el-option v-for="(option,i) in dealerList" :key="i" :label="option.name" :value="option.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="接收用户">
				<el-select v-model="userArr" placeholder="请选择用户" :disabled="readonly"
					multiple filterable reserve-keyword remote clearable :remote-method="remoteMethod"
					:loading="loading" class="full-width">
					<el-option v-for="(option,i) in userList" :key="i" :label="option.fullName" :value="option.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="考前须知" prop="remark">
				<el-input size="small" type="textarea" :rows="3" v-model="dataForm.remark" placeholder="考前须知"
					:disabled="readonly">
				</el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button class="submit-btn" @click="submit" v-if="title != '查看培训'">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
	import {
		debounce
	} from '@/utils/debounce'
	import vPinyin from '@/utils/characterToPinYing'
	export default {
		data() {
			return {
				title: '新增培训',
				visible: false,
				multiple: false,
				readonly: false,
				loading: false,
				departmentOptions: [],
				courseList: [],
				dealerList: [],
				userList: [],
				cascaderArr: [],
				cascaderArrTemp: [],
				dealerArr: [],
				userArr: [],
				courseCode: '',
				sendType: 1, //1通讯组 5经销商分组 3用户
				submitUrl: 'addTrainOnlines',
				publishFlag: 0,
				machineProps: {
					emitPath: false,
					multiple: true,
					value: 'id',
					label: 'label',
					children: 'children'
				},
				dataForm: {
					courseId: '',
					startTime: '',
					endTime: '',
					remark: '',
					sendTargetList: []
				},
				dataRule: {
					courseId: [{
						required: true,
						message: '请选择课程',
						trigger: "change"
					}, ],
					startTime: [{
						required: true,
						message: '开始时间不能为空',
						trigger: "blur"
					}, ],
					endTime: [{
						required: true,
						message: '结束时间不能为空',
						trigger: "blur"
					}, ]
				}
			}
		},
		watch: {
			sendType(v) {
				if (v == 1) {
					this.dealerArr = [];
				} else {
					this.cascaderArr = [];
				}
			},
			departmentOptions(val) {
				if (val.length > 0) {
					if (this.cascaderArrTemp.length > 0) this.cascaderArr = this.cascaderArrTemp
				}
			},
			cascaderArrTemp(val) {
				if (val.length > 0) {
					if (this.departmentOptions.length > 0) this.cascaderArr = val
				}
			},
		},
		methods: {
			init(type, data) {
				this.visible = true;
				this.getCourseList();
				this.getDepartmentList();
				this.getDealerGroupList();
				this.cascaderArr = [];
				this.cascaderArrTemp = [];
				this.dealerArr = [];
				this.userArr = [];
				this.userList = []
				this.departmentOptions = []
				this.dealerList = []
				this.courseList = []

				this.dataForm = {
					courseId: '',
					startTime: '',
					endTime: '',
					remark: '',
					sendTargetList: []
				}

				this.$nextTick(() => {
					this.$refs.dataForm.resetFields();
					this.dataForm.sendTargetList = [];
					this.courseCode = '';
					if (type == 'add') {
						this.getAllUserList();
						this.title = '新增培训';
						this.submitUrl = 'addTrainOnlines';
						this.readonly = false;
						this.multiple = true;
						this.publishFlag = 0;
						delete this.dataForm.id;
					} else if (type == 'edit') {
						this.title = '修改培训';
						this.multiple = false;
						this.submitUrl = 'updateTrainOnline';
						this.readonly = data.publishFlag == 1 || data.publishFlag == 2 ? true : false;
						this.publishFlag = data.publishFlag;
						this.handleShow(data);
					} else {
						this.title = '查看培训';
						this.multiple = false;
						this.submitUrl = '';
						this.readonly = true;
						this.publishFlag = '1';
						this.handleShow(data);
					}
				})

			},
			handleTime() {
				if (this.dataForm.courseId == '' || this.dataForm.startTime == '') {
					return
				}
				if(typeof this.dataForm.courseId == 'object' && this.dataForm.courseId.length > 1) {
					this.dataForm.endTime = this.$moment(new Date(this.dataForm.startTime).getTime()).add(40, "days").format('YYYY-MM-DD');
					return
				}
				let that = this;
				that.$request.post(
					"getTrainOnlineEndTime",
					true, {
						courseId: typeof this.dataForm.courseId == 'object' ? this.dataForm.courseId[0] : that.dataForm.courseId,
						startTime: that.dataForm.startTime
					},
					function (r) {
						if (r.code == "0") {
							that.dataForm.endTime = r.data.endTime;
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			},
			// 获取课程数据
			getCourseList() {
				let that = this;
				that.$request.post(
					"publishCourseList",
					true, {
						eduType: '1'
					},
					function (r) {
						if (r.code == "0") {
							that.courseList = r.data;
							that.courseList.forEach(item => {
								if (item.id == that.dataForm.courseId + '') {
									that.courseCode = item.code;
								}
							})
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			},
			// 获取通讯组数据
			getDepartmentList() {
				let that = this
				that.$request.get(
					"sysDepartSelector",
					false, {},
					function (r) {
						if (r.code == "0") {
							if (r.data && r.data.tree) {
								that.departmentOptions = r.data.tree;
							}
						} else {
							that.$message.error(r.msg);
						}
					}
				)
			},
			// 获取经销商分组
			getDealerGroupList() {
				let that = this;
				that.$request.get(
					"dealerGroupList",
					true, {},
					function (r) {
						if (r.code == "0") {
							that.dealerList = r.data;
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			},
			// 获取所有用户数据
			getAllUserList(dealerId,searchKey) {
				this.loading = true;
				let that = this
				that.$request.get(
					"sysUserAll",
					false, {
						searchKey: searchKey,
						dealerId: dealerId
					},
					function (r) {
						that.loading = false;
						if (r.code == "0") {
							that.userList = r.data;
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			remoteMethod(query) {
				if (query == '') {
					return
				}
				this.getAllUserList(this.userArr.join(','),query);
			},
			// 课程下拉
			handleCourse() {
				// 新增
				if(typeof this.dataForm.courseId == 'object') {
					let codes= [];
					this.courseList.forEach(item => {
						this.dataForm.courseId.forEach(cid => {
							if (item.id == cid) {
								codes.push(item.code);
							}
						})
					})
					this.courseCode = codes.join(',');
				}else{
					this.courseList.forEach(item => {
						if (item.id == this.dataForm.courseId + '') {
							this.courseCode = item.code;
						}
					})
				}
				this.handleTime();
			},
			// 课程名称
			deviceCascaderVisableChange(value) {
				// this.dataForm.name = this.$refs['myCascader'].getCheckedNodes()[0].label;
				// this.dataForm.nameId = this.$refs['myCascader'].getCheckedNodes()[0].value;
				console.log(this.cascaderArr)
			},
			handleShow(dt) {
				let that = this;
				that.$request.post(
					"trainOnlineInfo",
					true,
					{
						id: dt.id
					},
					function (r) {
						if (r.code == "0") {
							that.dataForm.id = r.data.id;
							that.dataForm.courseId = r.data.courseId;
							that.handleCourse();
							that.dataForm.startTime = r.data.startTime;
							that.dataForm.endTime = r.data.endTime;
							that.dataForm.remark = r.data.remark;
							//1通讯组 5经销商分组 3用户
							r.data.sendList.forEach(item => {
								// 通讯组
								if (item.sendType == 1) {
									// that.cascaderArr.push(item.sendVal);
									that.cascaderArrTemp.push(item.sendVal);
								}
								// 用户
								else if (item.sendType == 3) {
									that.userArr.push(item.sendVal);
								}
								// 经销商分组
								else {
									that.dealerArr.push(item.sendVal);
								}
							})
							that.getAllUserList(that.userArr.join(','),'');
							that.sendType = that.dealerArr.length > 0 ? 5 : 1;
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			},
			// 提交
			submit: debounce(function () {
				// if(this.userArr.length == 0) {
				// 	this.$message.error('请选择接收用户');
				// 	return
				// }
				this.cascaderArr.forEach(item => {
					this.dataForm.sendTargetList.push({
						sendType: '1',
						sendVal: item,
						sendName: ''
					})
				})
				this.userArr.forEach(item => {
					this.dataForm.sendTargetList.push({
						sendType: '3',
						sendVal: item,
						sendName: ''
					})
				})
				this.dealerArr.forEach(item => {
					this.dataForm.sendTargetList.push({
						sendType: '5',
						sendVal: item,
						sendName: ''
					})
				})
				if (this.dataForm.sendTargetList.length == 0) {
					this.$message.error('接收对象和接收用户至少选择一项');
					return
				}
				if (this.visible == false) {
					return
				}
				this.$refs['dataForm'].validate(valid => {
					if (valid) {
						let obj = this.dataForm;
						if(typeof this.dataForm.courseId == 'object') {
							obj.courseId = this.dataForm.courseId.join(',');
						}
						let that = this;
						that.$request.post(
							that.submitUrl,
							true,
							obj,
							function (r) {
								if (r.code == "0") {
									that.visible = false;
									that.$emit('getOnlineList');
									that.$message.success('保存成功');
								} else {
									that.$message.error(r.msg);
								}
							}
						);
					} else {
						return false;
					}
				});
			}, 1000)
		}
	}

</script>
<style lang="scss" scoped>
	.dialog-title {
		display: block;
		text-align: center;

		span {
			color: #222;
			font-weight: 600;
			font-size: 20px;
		}

		img {
			width: 28px;
			float: right;
			cursor: pointer;
		}
	}

	.el-form-item {
		margin-bottom: 18px;

		.el-select {
			width: 100%;
		}
	}

	.submit-btn {
		width: 100%;
		background-color: #117FFC;
		color: #fff;
	}

	.role-dialog /deep/ .el-dialog {
		border-radius: 10px !important;
	}

	.role-dialog /deep/ .el-dialog__body {
		padding: 0 20px !important;
	}

	.role-dialog /deep/ .el-dialog__footer {
		padding: 20px 20px 40px !important;
	}

	.flex {
		display: flex;
	}

	.flex-1 {
		flex: 1;
	}

</style>
