<template>
	<div class="data-content">
		<div class="top-box">
			<button class="add-btn" @click="handleEditData('add')" v-if="perms.includes('train:online:add')">新增</button>
			<button class="import-btn" @click="handleExport" v-if="perms.includes('train:online:export')">导出</button>
			<div class="top-right-box">
				<label class="label">关键字</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
				<label class="label">状态</label>
				<el-select class="search-input" size="small" v-model="publishFlag" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option label="已发布" value="1"></el-option>
					<el-option label="待发布" value="0"></el-option>
				</el-select>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="getOnlineList">
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			 <el-table
				:data="dataList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				border
				style="width: 100%">
				<el-table-column
					label="NO."
					width="60">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="courseCode"
					show-overflow-tooltip
					label="课程编号">
				</el-table-column>
				<el-table-column
					prop="courseName"
					show-overflow-tooltip
					label="课程名称">
				</el-table-column>
				<el-table-column
					prop="startTime"
					show-overflow-tooltip
					label="培训开始日期">
					<template slot-scope="scope">
						<span>{{scope.row.startTime.replace(new RegExp('-','g'),'/')}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="endTime"
					show-overflow-tooltip
					label="培训结束日期">
					<template slot-scope="scope">
						<span>{{scope.row.endTime.replace(new RegExp('-','g'),'/')}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="createBy"
					show-overflow-tooltip
					label="创建者">
				</el-table-column>
				<el-table-column
					label="状态"
					align="center"
					width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.publishFlag == 1">已发布</span>
						<span v-if="scope.row.publishFlag == 0 || scope.row.publishFlag == 2">待发布</span>
					</template>
				</el-table-column>
				<el-table-column
					label="操作"
					width="130">
					<template slot-scope="scope">
						<button class="btn-blue" @click="handleEditData('check',scope.row)">查看</button>
						<button class="btn-blue" @click="handleEditData('edit',scope.row)" v-if="perms.includes('train:online:update') && [0,2].includes(scope.row.publishFlag)">编辑</button>
						<button class="btn-blue" @click="handleEditDataStatus(scope.row)" v-if="perms.includes('train:offline:publish') && [0,2].includes(scope.row.publishFlag)">发布</button>
						<button class="btn-red" @click="handleEditDataStatus(scope.row)" v-if="perms.includes('train:offline:revoke') && scope.row.publishFlag == 1">撤销</button>
						<button class="btn-red" @click="handleDeleteData(scope.row)" v-if="perms.includes('train:online:delete') && [0,2].includes(scope.row.publishFlag)">删除</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<edit-data ref="editData" @getOnlineList="getOnlineList"></edit-data>
	</div>
</template>
<script>
import EditData from './components/edit-data'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		Pagination
	},
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			searchKey: '',
			publishFlag: '',//1发布2撤销
			pageNo: 1,
			pageSize: 20,
			total: 0,
			dataList: [],
			visible: false
		}
	},
	created() {
		this.getOnlineList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 重置查询条件
		handleReset() {
			this.searchKey = '';
			this.publishFlag = '';
			this.pageNo = 1;
			this.getOnlineList();
		},
		// 获取培训列表数据
		getOnlineList() {
			let that = this;
			that.$request.post(
				"getTrainOnlineList",
				true,
				{
					searchKey: that.searchKey,
					publishFlag: that.publishFlag,//1发布2撤销
					pageNo: that.pageNo,
					pageSize: that.pageSize
				},
				function (r) {
					if (r.code == "0") {
						that.dataList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 新增/编辑/查看角色
		handleEditData(type,data) {
			this.$refs.editData.init(type,data);
		},
		handleSizeChange() {
			this.getOnlineList();
		},
		handleCurrentChange() {
			this.getOnlineList();
		},
		handleSearch() {
			this.pageNo = 1;
			this.getOnlineList();
		},
		// 删除
		handleDeleteData(data) {
			this.$confirm('是否删除当前培训?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'deleteTrainOnline',
					true,
					{
						id: data.id
					},
					function (r) {
						if (r.code == "0") {
							that.getOnlineList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		// 更改培训状态
		handleEditDataStatus(dt) {
			let tips = dt.publishFlag == 1 ? '是否撤销当前培训?' : '是否发布当前培训?';
			this.$confirm(tips, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					dt.publishFlag == 1 ? 'courseTrainOnlineCancel' : 'courseTrainOnlinePub',
					true,
					{
						id: dt.id
					},
					function (r) {
						if (r.code == "0") {
							that.getOnlineList();
							that.$message.success('培训状态已变更');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		// 导出
		handleExport() {
			let that = this
			that.$request.post(
				"courseTrainOnlineDownload",
				true,
				{
					searchKey: that.searchKey,
					publishFlag: that.publishFlag
				},
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
	}
}
</script>
<style lang="scss" scoped>
.block{
	display: block;
}
.data-content{
	.top-box{
		height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>
<style>
	.el-message .user-message{
		margin: 15px;
	}
</style>
